import { OPEN_MODAL, OPEN_COLLAPSE, FOOTER_MODAL_OPEN, 
        SET_CONTENT_TYPE, OPEN_PROJECTS, OPEN_FAQ,OPEN_SKILLS, 
        SET_SELECTED_IMAGE_INDEX, UPDATE_CONTACT_INFO} from "../actions/datasAction"

const initialState = { 
    isModalOpen: false,
    isCollapseOpen: false,
    contentType: null,
    projects:[],
    faq:[],
    skills:[],
    selectedImageIndex: null,
    contactInfo: [],
    footerModalOpen: {
        footerModalIsOpen: false,
        buttonText: null,
      },
}

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                ...state,
                isModalOpen: action.payload,
            };
        case OPEN_COLLAPSE:
            return {
                ...state,
                isCollapseOpen: action.payload,
            };
        case FOOTER_MODAL_OPEN:
            return {
                ...state,
                footerModalOpen: {
                    footerModalIsOpen: action.payload.footerModalIsOpen,
                    buttonText: action.payload.buttonText,
                }
            };
        case SET_CONTENT_TYPE:
            return {
                ...state,
                contentType: action.payload,
            };
        case SET_SELECTED_IMAGE_INDEX:
            return {
                ...state,
                selectedImageIndex: action.payload,
            };
        case OPEN_PROJECTS:
            return {
                ...state,
                projects: action.payload,
            };
        case OPEN_FAQ:
            return {
                ...state,
                faq: action.payload,
            };
        case OPEN_SKILLS:
            return {
                ...state,
                skills: action.payload,
            };
        case UPDATE_CONTACT_INFO:
            return {
                ...state,
                contactInfo: {
                  ...state.contactInfo, 
                  ...action.payload,    
                },
              };
        default:
            return state;
    }
}

export default dataReducer;