import { useDispatch, useSelector } from 'react-redux';
import { setSelectedImageIndex, openModal, setContentType } from "../redux/actions/datasAction";

const Slider = () => {
  const dispatch = useDispatch();
  const datasProject = useSelector((state) => state.dataReducer.projects);

  const handleImageClick = (index) => {  
    dispatch(setSelectedImageIndex(index));
    dispatch(setContentType("SliderImage"));
    dispatch(openModal(true));
  };

    return ( 
      <ol className="slider-list">  
        {datasProject.map((item, index) => (
          <li key={index} className={`slider-list__item ${index ? 'selected' : ''}`} id={`image-${index}`}>
            <figure className='slider-list__item-figure' onClick={() => handleImageClick(index)}>
              <img 
                  className="slider-list__item-image" 
                  src={item.cover} 
                  alt={`cover - ${item.title}`}
                  data-index={index}
              />
              <h2 className="slider-list__item-title">{item.title}</h2>
            </figure>
          </li>
        ))}
      </ol>      
    )
}

export default Slider;