import Header from "../components/Header";
import Footer from "../components/Footer";
import Portrait from "../assets/Virginie RUDOWSKI - portrait.webp"

const About = () => {
    return (
        <>
        < Header />
        <main className="main-About">
            <h1>Faisons connaissance!</h1>
            <div className="section-About">
                <section className="section-About__hero">
                    <img src={ Portrait } alt="Virginie RUDOWSKI Portrait"></img>
                    <a className="credit-link" href="https://marinecphotographie.fr/"       target="_blank" rel="noreferrer">
                        <p className="credit">
                            Portrait réalisé par 
                            Marine C
                        </p>
                    </a> 
                </section>
                <section className="section-About__presentation">
                    <article>
                        <h2> Si vous recherchez un super crac qui va "dopper" votre visibilité 
                            et vous proposez des designs dignes du Museum of Modern Art de New-York, 
                            laissez moi vous décevoir!</h2>
                        <div className="section-About__presentation-text">                        
                            <p>Je ne suis pas designer, mais je sais où trouver de bons modèles au goût du jour.</p>
                            <p>Je ne prétend pas être une super-crac, parce que dans le métier que j'exerce et qui évolue très rapidement,
                            on ne peut pas l'être.</p>
                            <p>Une chose est sûre : je suis Gersoise, et dans le Gers, on prend le temps d'échanger et de faire connaissance.</p>
                        </div>
                    </article>            
                    <article>
                        <h2>Vous voulez savoir pourquoi j'ai choisi ce métier?</h2>
                        <div className="section-About__presentation-text">
                            <p>Parce que, tout comme vous, un jour, j'ai eu besoin d'un site pour mon association. 
                                Je savais qu'il était possible de faire mieux et plus pratique. 
                                Alors j'ai appris, d'abord en auto-didacte puis en obtenant un diplôme. </p>
                            <p> Mais je pouvais faire encore mieux !</p>
                            <p>J'ai quelques valeurs, et notamment, la préservation de notre planète. 
                                Lorsque je code, ou que j'utilise une librairie annexe, je fais toujours en sorte de minimiser 
                                mon code. Même un espace dans une ligne a un impact sur notre environnement !
                            </p>
                        </div>
                    </article>            
                    <article>
                        <h2>Qui je suis?</h2>
                        <div className="section-About__presentation-text">
                            <p>Un petit bout de femme, qui surf sur le web et qui aime rencontrer des gens.</p>
                            <p>Une passionnée de sports, de navigation ( sur l'eau ), de lancement de fusées, d'art numérique, 
                                mais qui a le sens pratique, parce que, je me cite: " Si cette invention me fait perdre du temps,
                                alors je vais faire en sorte qu'il m'en fasse gagner ! "
                            </p>
                        </div>
                    </article>  
                </section>  
            </div>        
        </main>
        < Footer />
        </>
    )
}

export default About;