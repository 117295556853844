import { NavLink } from "react-router-dom";
import { useState } from "react";

import Logo from "../components/Logo";

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header>
            <a className="logo-header" href="/">
                < Logo position="header" />
            </a>
            <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}> 
                <div className="hamburger-menu" onClick={toggleMenu}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div> 
                <div className="nav-links">              
                    <NavLink to="/">
                        Accueil
                    </NavLink>
                    <NavLink to="/services">
                        Services
                    </NavLink>
                    <NavLink to="/about">
                        A propos
                    </NavLink>
                    <NavLink to="/contact">
                        Contact
                    </NavLink>
                </div>
            </nav>
        </header>
    )
}

export default Header;