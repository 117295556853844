import Header from "../components/Header";
import Footer from "../components/Footer";

const Erreur = () => {
    return (
        <>
        < Header />
        <main className="main-Error"> 
            <h1>Oups !</h1>
            <p>Une erreur s'est produite</p>
            <a href="/"> Revenir à la page d'accueil</a>       
        </main>
        <Footer />
        </>
    )
}

export default Erreur;