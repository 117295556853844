import { useSelector } from "react-redux";

import Mentions from "./Mentions"
import ConditionsGU from "./CGU";
// import ConditionsGV from "./CGV";

const FooterModal = ({ isOpen, onClose }) => {
  const buttonText = useSelector((state) => state.dataReducer.footerModalOpen.buttonText);

  if (!isOpen) return null;

  let modalContent;

  switch (buttonText) {
    case "Mentions légales":
      modalContent = < Mentions />;
      break;
    case "CGU":
      modalContent = < ConditionsGU />;
      break;
    // case "CGV":
    //   modalContent = < ConditionsGV />;
    //   break;
    default:
      modalContent = null ;
  }

  const handleClickOutside = (event) => {
    if (event.target.classList.contains("modal-overlay")) {
      onClose();
    }
  };

  return (
    <div className="footer modal-overlay" onClick={handleClickOutside}>
      <div className={`footer-modal ${isOpen ? 'open' : 'closed'}`}>
        <button className="modal-close" onClick={onClose}>
              &times;
        </button>      
        {modalContent}
      </div>      
    </div>
  );
};

export default FooterModal;
