import Collapse from "../components/Collapse"

const ConditionsGU = () => {
    return (
        <section className="conditionsGU">
            <h2>Conditions générales d'utilisation du site Office Web Design</h2>
            < Collapse
                index="CGU1"
                title="Responsabilités"
            >
                <p>
                Office Web Design ne peut être tenu pour responsable d’éventuels dommages,
                 directs ou indirects, 
                survenant à l’occasion de l’accès ou des difficultés d’accès à ce site, 
                ou de l’utilisation qu’il pourrait en être fait, y compris les virus 
                susceptibles de contaminer la configuration du matériel informatique de l’utilisateur.
                Le site se réserve le droit d’apporter des modifications, des améliorations et 
                des changements sur les produits et les programmes décrits sur son site à tout moment et 
                sans le notifier.
                </p>
            </Collapse>
            < Collapse
                index="CGU2"
                title="Droits d'auteur"
            >
                <p>
                Nous avisons les utilisateurs de ce site que de nombreux éléments
                sont protégés par la législation sur le droit d’auteur : 
                ce peut être notamment le cas des photographies, des articles, des dessins, 
                des séquences animées, logo ... ;
                Les éléments ainsi protégés sont la propriété de Office Web Design  
                ou de tiers ayant autorisé le Office Web Design à les exploiter.
                Toute reproduction, représentation, utilisation, adaptation, modification, incorporation, 
                traduction, commercialisation, partielles ou intégrales quel que soit le procédé et 
                quel que soit le support (papier, numérique, …) sont interdites, sans l’autorisation 
                écrite préalable de Office Web Design , hormis les exceptions visées à l’article L 122.5 
                du Code de la Propriété Intellectuelle, sous peine de constituer un délit de contrefaçon de droit 
                d’auteur et/ou de dessins et modèles et/ou de marque, puni de deux ans d’emprisonnement et de 150 000€ 
                d’amende.
                </p>
            </Collapse>
            < Collapse
                index="CGU3"
                title="Liens de référence"
            >
                <p>
                Loi 78-17 du 6 janvier 1978 Loi relative à l’informatique, aux fichiers et aux libertés
                Loi du 29 juillet 1881 Loi sur la liberté de la presse
                Loi 2000-719 du 1er août 2000 Extrait de la loi portant sur la responsabilité des 
                prestataires techniques
                </p>
            </Collapse>
            < Collapse
                index="CGU4"
                title="Données personnelles"
            >
                <p>
                    Office Web Design ne collecte pas de données personnelles de manière automatique, 
                    notamment via l'utilisation de cookies. 
                    Aucun formulaire de contact n'est présent sur le site Office Web Design. 
                    Toute correspondance ou communication entre les utilisateurs et Office Web Design se fait exclusivement 
                    par le biais d'échanges par courrier électronique.
                    Les utilisateurs ayant choisi de communiquer avec Office Web Design par e-mail comprennent que toutes 
                    informations transmises via ce moyen peuvent être stockées et utilisées dans le cadre de la gestion des 
                    échanges et des services fournis, et Office Web Design s'engage à ne pas utiliser ces informations 
                    à des fins autres.
                    Les données personnelles transmises par les utilisateurs par e-mail ne seront pas conservées au-delà 
                    de la période nécessaire à la réalisation des finalités pour lesquelles elles ont été collectées, 
                    sauf si une obligation légale ou réglementaire impose une conservation plus longue.
                    Office Web Design s'engage à mettre en place des mesures de sécurité raisonnables pour protéger les 
                    données personnelles contre tout accès non autorisé, toute divulgation, toute altération ou toute 
                    destruction.
                    Aucune donnée personnelle collectée par Office Web Design n'est partagée, vendue, louée ou échangée 
                    avec des tiers, sauf accord explicite de l'utilisateur ou dans les cas prévus par la loi.
                    Conformément à la réglementation en vigueur sur la protection des données personnelles, les utilisateurs 
                    disposent du droit d'accéder, de rectifier, de supprimer, de limiter le traitement, et de 
                    s'opposer au traitement de leurs données personnelles. Ces droits peuvent être exercés en contactant 
                    le site par e-mail à owd.HappyWebWorld@gmail.com.
                </p>
            </Collapse>
            < Collapse
                index="CGU5"
                title="Liens hypertextes"
            >
                <p>
                Les domaines vers lesquels mènent les liens hypertextes présents sur le site n’engagent 
                pas la responsabilité de l’Éditeur de Office Web Design, qui n’a pas de contrôle sur ces liens.
                Il est possible pour un tiers de créer un lien vers une page du site Office Web Design sans 
                autorisation expresse de l’éditeur.
                </p>
            </Collapse>
            < Collapse
                index="CGU6"
                title="Droit applicable et juridiction compétente"
            >
                <p>
                Le présent contrat dépend de la législation française. 
                En cas de litige non résolu à l’amiable entre l’Utilisateur et l’Éditeur, 
                les tribunaux de Auch et Toulouse sont compétents pour régler le contentieux.
                </p>
            </Collapse>
        </section>
    );
  };
  
  export default ConditionsGU;
  