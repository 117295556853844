import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { openModal } from "../redux/actions/datasAction";

const Modal = ({ isOpen, onClose, datasProject, faqDatas }) => {
  const dispatch = useDispatch();
  const contentType = useSelector((state) => state.dataReducer.contentType);
  const contactInfo = useSelector((state) => state.dataReducer.contactInfo);
  const selectedImageIndex = useSelector((state) => state.dataReducer.selectedImageIndex);
  const datasSkills = useSelector((state) => state.dataReducer.skills);
  const navigate = useNavigate();
  
  if (!isOpen) return null;

  const handleClickOutside = (event) => {
    if (event.target.classList.contains("modal-overlay")) {
      onClose();
    }
  };

  const handleClickLink = () => {
    dispatch(openModal(false));
    navigate("/contact");
  };

  let modalClassName = "modal";
  let content;

  switch (contentType) {
    
    case "SliderImage": 
      modalClassName += " slider-modal";  
      content = (
        <>
          <h3 className="modal-title">{datasProject[selectedImageIndex]?.title}</h3>
          <div className="modal-container">
            <div className="modal-container__list">
              {selectedImageIndex === 3 ? (
                <ol className="modal-container__list-skills">
                  {datasSkills.map((skill, index) => (
                    <li key={index}>{skill.title}</li>
                  ))}
                </ol>
                ) : ( 
                  <div className="modal-container__list">{datasProject[selectedImageIndex]?.list}</div>
                )
              }
            </div>          
            <div className="modal-container__description">
              {datasProject[selectedImageIndex]?.description}
            </div>
            <div className="modal-container__feedback">
              {datasProject[selectedImageIndex]?.feedback}
            </div>            
            { selectedImageIndex === undefined || selectedImageIndex !== 0 ? (
              <button className="modal-container__button-link" onClick={handleClickLink}>
                Parlons de votre projet
              </button>
            ) : null }            
          </div>
        </>
      );
    break;
    case "FAQ":
      modalClassName += " faq-modal";
      content = (
        <>
        <h2>Je répond à vos questions les plus fréquentes.</h2>
          {faqDatas.map((faqItem, index) => (
            <div key={index} className="modal-description">
              <p className="modal-description__answer">{faqItem.answer}</p>
              <p className="modal-description__response">{faqItem.response}</p>
            </div>
          ))}
        </>
      );
    break;
    case "Phone":
      modalClassName += " modal-contact";
      
      content = (
        <>
          <h2>Parlez moi de votre projet de vive voix au :</h2>
          <a className="modal-link" href={`tel:${contactInfo.phone}`}>
            <p>{contactInfo.phone}</p>
          </a>
          <p className="modal-link">{contactInfo.Hours}</p>
        </>
      );
    break;
    case "Mail":
      modalClassName += " modal-contact";
      const subject = encodeURIComponent("Demande d'informations");
      const body = encodeURIComponent(`
        Bonjour, 

        (Afin de ne pas oublier les informations essentielles, je vous ai pré-rempli ce mail.)

        Je suis: 

        Je suis joignable de préférence ( mail, téléphone, visio, un créneau horaire...): 

        Je souhaite ( créer mon site, je recherche un développeur... ): 
        
        ( dites m'en plus si vous le souhaitez )
    `);

      content = (
        <>
          <h2>Contactez moi par mail : </h2>
          <a className="modal-link sending-link" href={`mailto:${contactInfo.email}?subject=${subject}&body=${body}`}>
            Envoyer un mail
          </a>
        </>
      );
    break;
    case "LinkedIn":
      modalClassName += " modal-contact";
      content = (
        <>
          <h2>Découvrez moi sur LinkedIn</h2>
          <a className="modal-link sending-link" href={contactInfo.LinkedIn} target="_blank" rel="noreferrer">
            LinkedIn
          </a>
        </>
      );
    break;
    case "GitHub":
      modalClassName += " modal-contact";
      content = (
        <>
          <h2>Découvrez mes précédents projets : </h2>
          <a className="modal-link sending-link" href={contactInfo.GitHub} target="_blank" rel="noreferrer">
            GitHub
          </a>
        </>
      );
    break;
    default:
      content = null;
  }

  return (
    <div className="modal-overlay" onClick={handleClickOutside}>
      <div className={modalClassName}>
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        {content}
      </div>
    </div>
  );
};

export default Modal;
