import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { fetchProjects, openModal, setContentType, setSelectedImageIndex } from "../redux/actions/datasAction";

import Button from "../components/Button";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Slider from "../components/Slider";
import Modal from "../components/Modal";

const Accueil = () => {
    const dispatch = useDispatch();
    const isModalOpen = useSelector((state) => state.dataReducer.isModalOpen);
    const datasProject = useSelector((state) => state.dataReducer.projects);
    const faqDatas = useSelector((state) => state.dataReducer.faq);
    const filteredFaqDatas = faqDatas.filter(faqItem => faqItem.position === "accueil");
    
    useEffect(() => {
        dispatch(fetchProjects());
    }, [dispatch]);
    
    const handleClick = (contentType) => {        
        dispatch(setContentType(contentType));
        dispatch(openModal(true)); 
    }

    return (
        <>
        < Header />
        <main className="main-Accueil"> 
            <h1>La création de votre site Web</h1>
            <div className="introduction">
                <p className="introduction__sloggan">Ne soyez plus invisibles, montrez vous à vos clients !</p>
                <p className="introduction__cta">Je vous explique tout ici </p>
                <p>⇩</p>
            </div>
            <section className="slider">               
                < Slider onClick={handleClick}/>
            </section>
            <Button 
                onClick={handleClick}
                caseType="FAQ"
                className="button-more"
                label="FAQ"
            />
            <Modal
                isOpen={isModalOpen}
                onClose={() => {
                    setSelectedImageIndex(null);
                    dispatch(openModal(false))
                }}
                datasProject={datasProject}
                faqDatas={filteredFaqDatas}
            />
        </main>
        <Footer/>
        </>
    )
}

export default Accueil;