import { useSelector, useDispatch } from "react-redux";
import { useEffect } from 'react';
import { fetchProjects, openModal, setContentType, openCollapse } from "../redux/actions/datasAction";
import { Link } from "react-router-dom";

import Modal from "../components/Modal";
import Button from "../components/Button";
import Collapse from "../components/Collapse";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Services = () => {
    const dispatch = useDispatch();

    const isModalOpen = useSelector((state) => state.dataReducer.isModalOpen);
    const faqDatas = useSelector((state) => state.dataReducer.faq);
    const filteredFaqDatas = faqDatas.filter(faqItem => faqItem.position === "services");

    useEffect(() => {
        dispatch(fetchProjects());
        dispatch(openCollapse(null));
    }, [dispatch]);

    const handleOpenModal = () => {
        dispatch(setContentType("FAQ"));
        dispatch(openModal(true));
    };

    const handleClick = () => {
        handleOpenModal(null);
      };

    return (
        <>
        < Header />
        <main className="main-Services">
            <section className="services-description">
                <h1>Services</h1>
                <p>Tout se passe en 4 étapes : du déroulement , au tarif de base, je vous dis tout ici !</p>
                <p>N'hésitez pas à consulter les FAQ ou à me contacter si vous souhaitez en savoir plus.</p>
                < Button 
                    onClick={() => handleClick("FAQ")}
                    label="FAQ"
                    className="button-more"
                />
            </section>
            <section className="services-items">
                <Collapse
                    index="1"
                    title="Création de sites"
                    background="background1"
                >
                    <h3>Création de votre site</h3>
                    <p className="services-items__price">À partir de 500€ HT ( 600€ TTC ) : </p>
                    <ol>
                        <li> Vous me parlez de vous et de votre activité </li>
                        <li> Je vous propose un ou plusieurs designs</li>
                        <li> Vous me faites parvenir les éléments nécessaires</li>
                        <li> Nous faisons ensemble une revue de votre site avant sa mise en ligne </li>
                    </ol>
                    <h3>Refonte de votre ancien site *</h3>
                    <p className="services-items__price">À partir de 350€ HT ( 420€ TTC ) : </p>
                    <ol>
                        <li> Nous voyons ensemble les éléments qui nécessitent une refonte de votre site actuel </li>
                        <li> Je vous propose des axes d'amélioration</li>
                        <li> Vous me faites parvenir les éléments nécessaires au besoin ( s'il y en a )</li>
                        <li> Nous faisons ensemble une revue de votre site avant sa mise en ligne </li>
                    </ol>
                    <p className="services-items__price"> * Votre site n'est pas désactivé durant la maintenance</p>
                    <Link to="/contact" className="button-link">
                        Parlons de votre projet
                    </Link>
                </Collapse>
                <Collapse
                    index="2"
                    title="Référencement"
                    background="background2"
                >
                    <h3>Optimisation technique de votre référencement ( SEO )</h3>
                    <p className="services-items__price">À partir de 250€ HT ( 400€ TTC ) : </p>
                    <ol>
                        <li>Je réalise un audit de votre site.</li>
                        <li>Je vous propose des corrections techniques. </li>
                        <li>Dès que vous acceptez le devis et que vous m'avez envoyé les éléments nécessaires 
                            ( identifiants de votre hébergeur ... ), je procède aux corrections. 
                            Le fonctionnement de votre site n'est pas interrompu durant cette période.
                        </li>
                        <li>Nous faisons ensemble une revue de votre site corrigé, avant sa mise en ligne.</li>
                    </ol>
                    <Link to="/contact" className="button-link">
                        Parlons de votre projet
                    </Link>
                </Collapse>
                <Collapse
                    index="3"
                    title="Intervention"
                    background="background3"
                >
                    <h3>Chef de projet, Designer, Entreprise, besoin d'une intervention ponctuelle ?</h3>
                    <p>Consultez le code de ce site pour en savoir plus sur mes compétences.</p>
                    <p>Je m'adapte à vos espaces de travail collaboratif ainsi qu'à vos plateformes. </p>
                    <p> Je suis " AGILE friendly " et rodée au travail hybride.</p>                   
                    <Link to="/contact" className="button-link">
                        Parlons de votre projet
                    </Link>
                </Collapse>
            </section>
            <Modal
                isOpen={isModalOpen}
                onClose={() => {
                    dispatch(openModal(false))
                }}
                faqDatas={filteredFaqDatas}
            />
        </main>
        <Footer/>
        </>
    )
}

export default Services;