import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Provider } from 'react-redux';
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./redux/reducers/index";

import '../src/styles/index.scss';
import Accueil from '../src/pages/Accueil'
import Contact from '../src/pages/Contact'
import Erreur from '../src/pages/Erreur'
import About from './pages/About'
import Services from '../src/pages/Services'

const store = configureStore({
  reducer: rootReducer,
  // devTools: true,
  devTools: false,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="*" element={<Erreur />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
