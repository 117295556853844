import { useSelector } from 'react-redux';

import LogoImage from "../assets/Logo Office Web Design.webp"

const Logo = ( { position } ) => {
    const skills = useSelector((state) => state.dataReducer.skills);

    return (
        <>
        <div className={`logo ${position}`}>
            <div className="logo__wrapper">   
                <img className="logo__img" src={LogoImage} alt="Logo Office Web Design"></img>
            </div>
            <ol className="logo__tags">
                {skills.map((item, index) => (
                    <li key={index}>
                        <p>{item.title}</p>
                    </li>
                ))}
            </ol>
        </div>
        </>
    )
}

export default Logo;