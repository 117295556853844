const Mentions = () => {
  return (
    <div className="mentions-container">
      <h2>Mentions légales :</h2>
        <article>
          <h3>AE Office Web Design</h3>
          <p>N° de SIRET: 982 679 748 00011</p>
          <p>
              Virginie RUDOWSKI
              5, chemin de Vitrac
              32200 GIMONT
          </p>
          <p>tél: 06 77 87 44 91</p>
          <p>mail: owd.HappyWebWorld@gmail.com</p>
        </article>
        <article>
          <h3>Hébergement : </h3>
          <p>IONOS SARL                                                                       
              7, place de la Gare                                                                                     
              BP 70109                                                                                            
              57200 Sarreguemines Cedex
              France
              tél: 09 70 80 89 11
          </p>
        </article>
        <article>
          <h3>Crédits : </h3>
          <p>
              Ce site à été réalisé par Office Web Design. Toutes les images utilisées, sont libres de droits. 
              Le portrait a été réalisé par 
          </p>
          <p>
            <a href="https://marinecphotographie.fr/" target="_blank" rel="noreferrer">Marine C</a>
          </p>
        </article>
    </div>
  );
};

export default Mentions;
