import { useSelector, useDispatch } from "react-redux";
import { FooterModalOpen } from "../redux/actions/datasAction";

import Logo from "./Logo";
import FooterModal from "./FooterModal";

const Footer = () => {

    const dispatch = useDispatch();

    const footerModalIsOpen = useSelector((state) => state.dataReducer.footerModalOpen.footerModalIsOpen);

    const handleClick = (e) => {
        const buttonText = e.target.textContent;
        dispatch(FooterModalOpen(true, buttonText))       
    };

    return (
        <footer>
            <section className="footer-left-section">
                <p>
                    Retrouvez Office Web Design dans tout le Sud Ouest, Toulouse, Auch, Montauban, Agen,
                     Bordeaux, Mont-de-Marsan, mais aussi dans la France entière en travail à distance. 
                </p>
            </section>            
            <section className="footer-right-section">
                < Logo position="footer"/>
                <ul>                
                    <li onClick={handleClick}>
                        Mentions légales
                    </li>
                    <li onClick={handleClick}>
                        CGU
                    </li>
                    {/* <li onClick={handleClick}>
                        CGV
                    </li>                     */}
                </ul>
            </section>
            <FooterModal
                isOpen={footerModalIsOpen}
                onClose={() => {
                    dispatch(FooterModalOpen(false, ""))
                }}
            />
        </footer>
    )
}

export default Footer;