import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { fetchProjects, openModal, setContentType } from "../redux/actions/datasAction";

import Modal from "../components/Modal";
import Button from "../components/Button";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Contact = () => {
    const dispatch = useDispatch();
    const isModalOpen = useSelector((state) => state.dataReducer.isModalOpen);

    useEffect(() => {
        dispatch(fetchProjects());
    }, [dispatch]);

    const handleOpenModal = (contentType) => {
        dispatch(setContentType(contentType));
        dispatch(openModal(true));
    };

    const handleClick = ( buttonType) => {
        handleOpenModal(buttonType);       
      };

    return (
        <>
        < Header />
        <main className="main-Contact">
            <h1>Contact</h1>            
            <section className="contact-items">
                <div className="contact-items__left-part">                    
                    <Button 
                        onClick={handleClick}
                        caseType="Phone" 
                        className="phone" 
                        label="Téléphone"
                    />
                    <Button 
                        onClick={handleClick}
                        caseType="Mail"
                        className="mail"
                        label="E-mail"
                    />
                </div>
                <div className="contact-items__right-part">
                    <Button 
                        onClick={handleClick}
                        caseType="LinkedIn" 
                        className="linkedin" 
                        label="LinkedIn"
                    />
                    <Button 
                        onClick={handleClick}
                        caseType="GitHub" 
                        className="github" 
                        label="GitHub"
                    />
                </div>
                <Modal 
                    isOpen={isModalOpen} 
                    onClose={() => dispatch(openModal(false))}
                />
            </section>
        </main>
        <Footer/>
        </>
    )
}

export default Contact;