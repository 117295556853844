export const OPEN_MODAL = 'OPEN_MODAL'
export const OPEN_COLLAPSE = 'OPEN_COLLAPSE'
export const SET_CONTENT_TYPE = 'SET_CONTENT_TYPE';
export const OPEN_PROJECTS = 'OPEN_PROJECTS'
export const OPEN_FAQ = 'OPEN_FAQ'
export const OPEN_SKILLS = 'OPEN_SKILLS'
export const SET_SELECTED_IMAGE_INDEX = 'SET_SELECTED_IMAGE_INDEX';
export const UPDATE_CONTACT_INFO = 'UPDATE_CONTACT_INFO';
export const FOOTER_MODAL_OPEN = 'FOOTER_MODAL_OPEN';

export const fetchProjects = () => {
    return async (dispatch, getState) => {
      const datasProject = getState().dataReducer.projects;
  
      if (!datasProject || datasProject.length === 0) {
        try {
          const response = await fetch("/datas/datas.json");
          const data = await response.json();
          
          const projects = data.projects;
          const faqDatas = data.faq;
          const skills = data.skills;
          const contactInfo = data.contact;
  
          dispatch(openProjects(projects));
          dispatch(openFaq(faqDatas));
          dispatch(openSkills(skills));
          dispatch(updateContactInfo(contactInfo));
        } catch (error) {
          console.error('Erreur lors du chargement des données', error);
        }
      }
    };
};

export const openModal = (isModalOpen) => ({
    type: OPEN_MODAL,
    payload: isModalOpen,
});

export const openCollapse = ( isCollapseOpen ) => ({
  type: OPEN_COLLAPSE,
  payload: isCollapseOpen,
});

export const setContentType = (contentType) => {    
    return {
      type: SET_CONTENT_TYPE,
      payload: contentType,
    };
  };

export const openProjects = (projects) => ({
    type: OPEN_PROJECTS,
    payload: projects,
});

export const openFaq = (faq) => ({
    type: OPEN_FAQ,
    payload: faq,
});

export const openSkills = (skills) => ({
    type: OPEN_SKILLS,
    payload: skills,
});

export const setSelectedImageIndex = (index) => ({
    type: SET_SELECTED_IMAGE_INDEX,
    payload: index,
});

export const updateContactInfo = (newContactInfo) => ({
    type: UPDATE_CONTACT_INFO,
    payload: newContactInfo,
});

export const FooterModalOpen = (footerModalIsOpen, buttonText) => ({
  type: FOOTER_MODAL_OPEN,
  payload: { footerModalIsOpen, buttonText },
});