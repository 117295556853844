const Button = ({ caseType, onClick, className, label }) => {

  return (
    <button 
      className={`${className}`} 
      onClick={() => onClick(caseType)}>
      {label}
    </button>
  );
};

export default Button;
