import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { openCollapse } from "../redux/actions/datasAction";

const Collapse = ({ index, title, background, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const dispatch = useDispatch();
  const collapseIndex = useSelector(state => state.dataReducer.isCollapseOpen);

  const toggleCollapse = (e) => {    
    const target = e.target.id;

    if (collapseIndex === index) {
      dispatch(openCollapse(null));
      setIsCollapsed(true);
    } else {
      dispatch(openCollapse(target));
      setIsCollapsed(!isCollapsed);
    }
  };

  return (
    <div id={index} className={`collapse ${collapseIndex === index ? 'expanded' : 'collapsed'}`}>
      <div
        id={index} 
        className={`collapse-header ${background}`}
        onClick={toggleCollapse}
      >
        <h2 id={index}>{title}</h2>
      </div>
      <div className={`collapse-content ${collapseIndex === index ? 'visible' : ''}`}>
        {children}
      </div>
    </div>
  );
};

export default Collapse;
